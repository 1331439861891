.add_bank {
  width: 80%;
  margin: 90px auto;
  background: #d7d7d7;
  padding: 30px;
  border-radius: 10px;
}
.error {
  color: red;
  font-size: 13px;
}
.navbar-nav a.active {
  background: #000;
}
#setting_sidebar button::after {
  width: 100%;
}
#setting_sidebar .dropdown-menu.show {
  position: unset;
  margin-top: 0px !important;
  background: transparent;
  margin: 0 40px 20px;
  padding: 0;
}
#setting_sidebar .dropdown-menu {
  background: transparent;
}
#setting_sidebar .dropdown-menu.show li a {
  background: transparent;
  color: #fff;
}
#setting_sidebar .dropdown-menu::before {
  position: unset;
  content: unset;
}

.gate_way tbody td {
  padding-left: 25px;
}

.custom-width-36 {
  white-space: unset !important;
  width: 36%;
}

.gate_way .text-danger {
  position: relative;
  top: 4px;
}
.pe-auto {
  cursor: pointer;
}

.add_input {
  gap: 20px;
}

.file_upload input[type="file"]::file-selector-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-indent: -9999px;
  width: 30px;
  white-space: nowrap;
}
.file_upload {
  position: relative;
}
.file_upload i {
  left: 0px;
  top: 0;
}
.file_upload svg {
  width: 25px !important;
}

.successfull span {
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
}
/* ::-webkit-scrollbar {
  height: 1px;
  width: 1px;
  background: #fff;
} */
::-webkit-scrollbar-thumb:horizontal {
  background: #fff;
}
.bg_viewpopup {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.bg_viewpopup .viewFiles,
.successfull {
  transform: translate(-50%, -50%);
}
.cross_icon {
  width: 40px;
  height: 40px;
  top: 6px;
}
.cross_icon label {
  position: relative;
  top: -4px;
  font-size: 30px;
  color: #fff;
  right: 2px;
}

.cross_icon12 {
  width: 25px;
  height: 25px;
  top: -13px;
  right: -10px !important;
}
.cross_icon12 label {
  position: relative;
  top: -3px;
  font-size: 20px;
  color: #fff;
  right: 2px;
}

.navbar-collapse,
.viewFiles .files {
  width: 100%;
  overflow: auto;
  height: 100%;
  overflow-x: hidden !important;
}
.navbar-collapse::-webkit-scrollbar {
  width: 0.1px;
  height: 8px;
  background-color: #2e2e32;
}
.viewFiles .files::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #ffffff;
}

.viewFiles .files::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.card .card-header .heading {
  font-size: 20px;
}
.card-footer span label {
  font-size: 13px;
  padding-right: 3px;
}
.card-footer span label:hover {
  color: #e43270 !important;
}

th,
td {
  font-size: 12px;
}
tbody {
  --bs-table-striped-bg: transparent !important;
}
.sidebarcollapse + .main-content {
  margin-left: 108px !important;
  z-index: 9999;
}
.heading_top + .container-fluid.py-3 {
  height: 100vh;
}
.sidebarcollapse ul li span,
.sidebarcollapse h6,
.sidebarcollapse .sidenav-header li {
  display: none;
}
.sidebarcollapse ul.navbar-nav ul {
  padding-left: 0;
}
.sidebarback {
  float: right;
  background: #fff;
  border: 1px solid;
  margin-right: 10px;
  color: #000;
  font-size: 25px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.sidebarcollapse .sidebarback {
  right: 20px;
  position: relative;
}
html body .navbar-vertical.navbar-expand-xs.sidebarcollapse {
  max-width: max-content !important;
}

main.main-content .container-fluid.py-4 {
  min-height: 100vh;
}
.DateRangePicker {
  position: absolute !important;
  background: #fff;
  top: 100%;
  left: 0;
  z-index: 9999;
  border: 1px solid;
  border-radius: 5px;
}
select.form-select option {
  background: #000;
}

.successfull::-webkit-scrollbar {
  width: 1em;
}

.successfull::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.successfull::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.popTable {
  height: 300px;
  overflow-y: scroll;
  min-height: 300px;
}
.popTable .cross_icon label {
  width: 96%;
  right: 0;
}

.popTable .cross_icon span {
  position: relative;
  top: 7px;
  font-size: 20px;
  color: #fff;
  right: 30px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  left: 100%;
  cursor: pointer;
}

.popTable .cross_icon {
  left: 0;
  width: 100%;
  transform: translate(0%, -100%);
  top: 19%;
  right: 0 !important;
}

.popTableTotal {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 999;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
}

@media only screen and (max-width: 1240px) and (min-width: 767px) {
  .heading_top + form {
    width: 95% !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .dateselect_navigation,
  .dateselect_navigation .d-flex {
    display: unset !important;
    float: unset !important;
  }
  .dateselect_navigation a button {
    width: 100%;
    margin: 0 !important;
  }
  .dateselect_navigation select.form-select {
    margin: 0 !important;
    margin-bottom: 4px !important;
  }
  .dateselect_navigation input {
    width: 100%;
  }
  .table-responsive th,
  .h6 {
    font-size: 14px !important;
  }
  .dateselect_navigation .position-relative.bg-gradient-dark.rounded {
    display: flex !important;
    margin-bottom: 4px;
  }

  .card .card-header .heading,
  h6 {
    font-size: 14px;
  }
  .header_topbar.headeercollapse {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .heading_top + form {
    width: 96% !important;
    margin-top: 20px !important;
  }
  .user_add {
    display: unset !important;
  }
  .user_add a button {
    float: unset !important;
    width: 100%;
    justify-content: center;
  }
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #000;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2e2e32;
  border-color: #000;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #2e2e32;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.header-back-btn {
  /* border: none; */
  margin-right: 10px;
  border-radius: 10px;
  /* background: transparent; */
}

.pagination-btn {
  gap: 15px !important;
  border-top: 3px solid #ddd;
  padding: 13px 0;
}
.pagination-btn ul.pagination {
  margin: unset;
}
.pagination-btn ul li a,
.pagination-btn ul li.previous a,
.pagination-btn ul li.next a {
  border-radius: 50%;
  margin: 0 5px;
  width: 35px;
  height: 35px;
  display: block;
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  padding: 7px;
}

.header_topbar h6 {
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 300;
}
